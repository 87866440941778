import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'
import FONTS from 'constants/fonts'

const NotFound = () => {
  return (
    <div>
      <Header />
      <div
        className="relative"
        style={{
          padding: '170px 0px'
        }}>
        <div
          className="absolute top-0 left-0 right-0 bottom-0"
          style={{
            backgroundColor: '#000000B3'
          }}></div>
        <div
          style={{
            zIndex: 2
          }}
          className="relative container flext items-center justify-center mx-auto">
          <p
            className="text-center text-white"
            style={{
              fontFamily: FONTS.extraBold,
              fontSize: '50px'
            }}>
            Error 404
          </p>
          <p className="text-white text-center">Page not found</p>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default NotFound
