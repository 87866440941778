interface DividerProps {
  top?: number | string
  bottom?: number | string
}
const Divider = ({ top, bottom }: DividerProps) => {
  return (
    <div
      style={{
        marginTop: top,
        marginBottom: bottom,
        height: 1,
        width: '100%',
        border: '1px solid #6A7280'
      }}></div>
  )
}

export default Divider
