import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { AiFillCloseCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'

interface SideMenuProps {
  visible: boolean
  close: any
}

const LinkText = ({ to, children }: any) => {
  return (
    <Link to={to}>
      <p
        style={{
          fontFamily: FONTS.regular,
          fontSize: '40px'
        }}
        className="text-white text-center">
        {children}
      </p>
    </Link>
  )
}
const SideMenu = ({ visible, close }: SideMenuProps) => {
  return (
    <div
      style={{
        zIndex: 20,
        backgroundColor: COLORS.gray,
        padding: '20px 40px',
        display: visible ? 'block' : 'none'
      }}
      className="fixed top-0 left-0 bottom-0 right-0 w-screen h-screen">
      <div className="flex flex-row items-center justify-between">
        <Link to="/">
          <img
            src={ImageAssets.logo}
            style={{
              width: '42px'
            }}
            alt=""
          />
        </Link>

        <AiFillCloseCircle onClick={close} cursor="pointer" color="white" size={32} />
      </div>
      <SizedBox height={20} />
      <div className="flex flex-col items-center gap-5">
        <LinkText to="/about">About</LinkText>
        <LinkText to="/service">Service</LinkText>
        {/* <LinkText to="/portfolio">Portfolio</LinkText> */}
        {/* <LinkText to="/pricing">Pricing</LinkText> */}
        <LinkText to="/careers">Careers</LinkText>
        <LinkText to="/contact">Contact Us</LinkText>
      </div>
    </div>
  )
}

export default SideMenu
