import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import SizedBox from 'components/SizedBox'
import { BsGlobeAmericas } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { IoIosCall } from 'react-icons/io'
import { useEffect } from 'react'
import PageHeroSection from 'components/PageHeroSection'
import ImageAssets from 'assets/imageAssets'

const ContactCard = ({ image, title, value }: any) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://link.gohighlevel.com/js/form_embed.js'
    script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <div
      style={{
        marginBottom: '15px'
      }}
      className="shadow-lg p-5 gap-5 flex flex-row items-center">
      {image}
      <div className="flex flex-col gap-1">
        <h3
          style={{
            fontFamily: FONTS.extraBold,
            fontSize: '22px'
          }}>
          {title}:
        </h3>
        <p
          style={{
            opacity: 0.6
          }}>
          {value}
        </p>
      </div>
    </div>
  )
}

const ContactPage = () => {
  return (
    <div>
      <Header />
      <PageHeroSection image={ImageAssets.contact} title="Contact Us" />
      <SizedBox height={50} />
      <div className="container mx-auto flex-col gap-5 flex md:flex-row">
        <div
          style={{
            padding: '20px'
          }}
          className="flex-1">
          <p
            style={{
              color: COLORS.primary,
              fontSize: '18px',
              fontFamily: FONTS.extraBold,
              lineHeight: '100%'
            }}
            className="uppercase">
            Contact details
          </p>
          <h1
            className="capitalize"
            style={{
              fontSize: '40px',
              color: COLORS.gray,
              fontFamily: FONTS.bold
            }}>
            Contact us?
          </h1>
          <SizedBox height={10} />
          <p
            style={{
              opacity: 0.6
            }}>
            Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours
            on business days. We will be happy to answer your questions.
          </p>
          <SizedBox height={10} />
          <div>
            <ContactCard
              image={<BsGlobeAmericas color={COLORS.primary} size={32} />}
              title="Address"
              value="We've moved our operations online"
            />
            <ContactCard
              image={<MdEmail color={COLORS.primary} size={32} />}
              title="Email"
              value="info@cheniussolutions.com"
            />
            <ContactCard
              image={<IoIosCall color={COLORS.primary} size={32} />}
              title="Phone"
              value="+1 (650) 683-9219"
            />
          </div>
        </div>
        <div
          className="flex-1"
          style={{
            backgroundColor: '#F3F4F6',
            padding: '20px'
          }}>
          <p
            style={{
              color: COLORS.primary,
              fontSize: '18px',
              fontFamily: FONTS.extraBold,
              lineHeight: '100%'
            }}
            className="uppercase">
            Get in touch
          </p>
          <h1
            className="capitalize"
            style={{
              fontSize: '40px',
              color: COLORS.gray,
              fontFamily: FONTS.bold
            }}>
            Ready to get started?
          </h1>
          <SizedBox height={10} />
          <iframe
            src="https://api.leadconnectorhq.com/widget/form/geaigU1plMiDK4xj6R3I"
            style={{
              display: 'none',
              width: '100%',
              height: '100%',
              border: 'none'
            }}
            id="inline-geaigU1plMiDK4xj6R3I"
            data-layout='{"id":"INLINE"}'
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="Website - Write Us"
            data-height="594"
            data-layout-iframe-id="inline-geaigU1plMiDK4xj6R3I"
            data-form-id="geaigU1plMiDK4xj6R3I"></iframe>
        </div>
      </div>
      <SizedBox height={20} />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d91323.66843514444!2d-122.01331518379772!3d37.49608961364542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb68ad0cfc739%3A0x7eb356b66bd4b50e!2sSilicon%20Valley%2C%20CA%2C%20USA!5e0!3m2!1sen!2skh!4v1674221434017!5m2!1sen!2skh"
        height="300"
        className="mx-auto"
        style={{
          border: 0,
          width: '98%'
        }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"></iframe>
      <SizedBox height={20} />
      <Footer />
    </div>
  )
}

export default ContactPage
