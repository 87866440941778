import ImageAssets from 'assets/imageAssets'
import SideMenu from 'components/menu/SideMenu'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
const Header = () => {
  const [showSide, setShowSide] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', function (_event: any) {
      const mHeader = document.querySelector('header')
      const menuHolder = document.querySelector('#menu-holder')
      const hamburger = document.querySelector('#hamburger')
      if (mHeader && menuHolder) {
        if (this.scrollY >= 100) {
          mHeader.style.backgroundColor = 'white'
          // @ts-expect-error
          menuHolder.style.color = 'black'
          // @ts-expect-error
          hamburger.style.color = 'black'
        } else {
          mHeader.style.backgroundColor = 'transparent'
          // @ts-expect-error
          hamburger.style.color = 'white'
          // @ts-expect-error
          menuHolder.style.color = 'white'
        }
      }
    })
  }, [])
  return (
    <header className="fixed shadow-sm z-10 top-0 bg-transparent w-screen p-7">
      <SideMenu visible={showSide} close={() => setShowSide(false)} />
      <div className="container mx-auto flex flex-row items-center justify-between">
        <Link to="/">
          <img
            style={{
              height: 60
            }}
            src={ImageAssets.logo}
            alt=""
          />
        </Link>
        <GiHamburgerMenu
          className="block md:hidden"
          onClick={() => setShowSide(true)}
          id="hamburger"
          style={{
            color: 'white'
          }}
          size={40}
        />
        <div
          id="menu-holder"
          style={{
            color: 'white'
          }}
          className="hidden md:flex flex-row items-center gap-10">
          <Link to="/about">
            <p className="text-sm">About</p>
          </Link>
          <Link to="/service">
            <p className="text-sm">Service</p>
          </Link>
          {/* <Link to="/portfolio">
            <p className="text-sm">Portfolio</p>
          </Link> */}
          {/* <Link to="/pricing">
            <p className="text-sm">Pricing</p>
          </Link> */}
          <Link to="/careers">
            <p className="text-sm">Careers</p>
          </Link>
          <Link to="/contact">
            <p className="text-sm">Contact Us</p>
          </Link>
        </div>
      </div>
    </header>
  )
}

export default Header
