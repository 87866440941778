import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { fadeInNoDelay } from 'utils/motion'

const ClientCard = ({ image, name, title, description }: any) => {
  return (
    <motion.div
      variants={fadeInNoDelay('up')}
      className="flex flex-1 shadow-lg flex-col items-center"
      style={{
        padding: '60px 40px'
      }}>
      <img
        style={{
          marginBottom: '20px',
          width: '90px',
          height: '90px'
        }}
        src={image}
        className="rounded-full"
        alt=""
      />
      <p
        style={{
          opacity: 0.6
        }}
        className="text-center text-sm">
        {description}
      </p>
      <div
        style={{
          margin: '20px',
          height: '5px',
          width: '40px',
          backgroundColor: COLORS.primary
        }}></div>
      <h4
        style={{
          fontFamily: FONTS.bold,
          fontSize: '20px',
          lineHeight: '90%'
        }}>
        {name}
      </h4>
      <p
        style={{
          color: COLORS.primary
        }}
        className="text-sm">
        {title}
      </p>
    </motion.div>
  )
}

export default ClientCard
