import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './pages/home/HomePage'
import ContactPage from './pages/contact/ContactPage'
import ServicePage from './pages/services/ServicePage'
import PricingPage from 'pages/pricing/PricingPage'
import PortfolioPage from 'pages/portfolio/PortfolioPage'
import AboutPage from 'pages/about/AboutPage'
import TermsPage from 'pages/terms/TermsPage'
import PrivacyPage from 'pages/privacy/PrivacyPage'
import CareersPage from 'pages/careers/CareersPage'
import NotFound from 'pages/404/NotFound'
import ScrollToTop from 'components/ScrollToTop'

function App() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App
