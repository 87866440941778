import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import ProvisionCard from './ProvisionCard'
import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'

const WhatWeProvide = () => {
  return (
    <StaggerWrapper>
      <div
        style={{
          margin: '60px 0px'
        }}>
        <div className="container mx-auto px-5 md:px-0">
          <div className="flex flex-col items-center">
            <motion.p
              variants={slideUpNoDelay()}
              style={{
                color: COLORS.primary,
                fontSize: '18px'
              }}
              className="font-bold">
              OUR SERVICES
            </motion.p>
            <motion.h3
              variants={slideUpNoDelay()}
              style={{
                fontFamily: FONTS.extraBold,
                fontSize: '40px',
                lineHeight: '90%'
              }}>
              What We Provide
            </motion.h3>
          </div>
          <SizedBox height={40} />
          <div className="flex w-10/12 mx-auto md:flex-row flex-col items-stretch gap-4">
            <ProvisionCard
              image={ImageAssets.girl}
              title="UI/UX Design"
              description="Fully customized designs with industry standard tools like Figma and Adobe XD"
            />
            <ProvisionCard
              image={ImageAssets.web}
              title="Web Development"
              description="Unique website for your niche, and full custom web applications for any projects, CRM, SIS, and others"
            />
            <ProvisionCard
              image={ImageAssets.mock}
              title="Mobile Development"
              description="Mobile solutions with both Hybrid and Native options, app store and play store uploads and maintanance are perks too."
            />
          </div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default WhatWeProvide
