import SizedBox from 'components/SizedBox'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import ProcessCard from './ProcessCard'
import ImageAssets from 'assets/imageAssets'
import { motion } from 'framer-motion'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import { slideUpNoDelay } from 'utils/motion'

const ProcessSection = () => {
  return (
    <StaggerWrapper>
      <div
        style={{
          padding: '60px 0px'
        }}>
        <div className="container mx-auto px-5 md:px-0">
          <div className="flex flex-col items-center">
            <motion.p
              variants={slideUpNoDelay()}
              style={{
                color: COLORS.primary,
                fontSize: '18px'
              }}
              className="font-bold">
              HOW IT WORKS
            </motion.p>
            <motion.h3
              variants={slideUpNoDelay()}
              className="text-center"
              style={{
                fontFamily: FONTS.extraBold,
                fontSize: '40px',
                lineHeight: '90%'
              }}>
              Check Out Our{' '}
              <span
                style={{
                  color: COLORS.primary
                }}>
                Work Process
              </span>
            </motion.h3>
          </div>
          <SizedBox height={40} />
          <div className="flex flex-col md:flex-row items-center gap-5">
            <ProcessCard
              title="Gather Information"
              description="All relevant information regarding the project is collected over a call."
              image={ImageAssets.process1}
              position={1}
            />
            <ProcessCard
              title="Create Prototype"
              description="Using the information received in phase 1, the design and tech development begins for a prototype."
              image={ImageAssets.process2}
              position={2}
            />
            <ProcessCard
              title="Test Prototype"
              description="The prototype that is created is reviewed and tested to ensure that all needs are met."
              image={ImageAssets.process3}
              position={3}
            />
            <ProcessCard
              title="Create Final Product"
              description="After the prototype testing is completed, the final product is creating for release."
              image={ImageAssets.process4}
              position={4}
            />
          </div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default ProcessSection
