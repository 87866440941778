import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import ClientCard from './ClientCard'
import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'

const ServiceClients = () => {
  return (
    <StaggerWrapper>
      <div
        style={{
          margin: '90px 0'
        }}>
        <div className="container mx-auto px-5 md:px-0">
          <div className="w-full md:w-6/12 mx-auto flex flex-col items-center">
            <motion.p
              variants={slideUpNoDelay()}
              style={{
                color: COLORS.primary,
                fontSize: '18px'
              }}
              className="font-bold">
              CLIENT TESTIMONIALS
            </motion.p>
            <motion.h3
              variants={slideUpNoDelay()}
              className="text-center"
              style={{
                fontFamily: FONTS.extraBold,
                fontSize: '40px',
                lineHeight: '90%'
              }}>
              What Do Our Happy{' '}
              <span
                style={{
                  color: COLORS.primary
                }}>
                Clients
              </span>{' '}
              Say About Our Service
            </motion.h3>
          </div>
          <SizedBox height={20} />
          <div className="flex flex-col md:flex-row items-stretch gap-4">
            <ClientCard
              image={ImageAssets.whiteWoman}
              name="Rebecca Peters"
              title="Online Seller"
              description="At first I wasnt sure if I should build a website for my small facebook shop, but in the end it was worth it. Them cordination throught the building process was amazing. Would work with Chenius Solutions again."
            />
            <ClientCard
              image={ImageAssets.whiteMan}
              name="Jonathan Ike"
              title="CFO"
              description="My team and I are grateful to Chenius Solutions for their amazing work on our custom CRM, automations, workflows, pipelines have never easier. They came up with an easy to use amazing design that my entire team loves."
            />
            <ClientCard
              image={ImageAssets.mixWoman}
              name="Grace Nilam"
              title="Nutritionist"
              description="I have never worked with a more diverse team before, the cordination from people around the world amazed me, add that to the fact that my dream website was completed in under a month. I am extremely grateful that I took the chance on Chenius Solutions"
            />
          </div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default ServiceClients
