import ImageAssets from 'assets/imageAssets'
import PageHeroSection from 'components/PageHeroSection'
import SizedBox from 'components/SizedBox'
import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'

const CareersPage = () => {
  return (
    <div>
      <Header />
      <PageHeroSection title="Careers" image={ImageAssets.contact} />
      <SizedBox height={50} />
      <StaggerWrapper>
        <div className="container mx-auto px-5 md:px-0">
          <motion.h2
            variants={slideUpNoDelay()}
            style={{
              fontFamily: FONTS.extraBold,
              fontSize: '40px'
            }}>
            Positions
          </motion.h2>
          <motion.p variants={slideUpNoDelay()}>
            There are no available positions, but dont hesitate to check at a later date.
          </motion.p>
        </div>
      </StaggerWrapper>
      <SizedBox height={50} />
      <Footer />
    </div>
  )
}

export default CareersPage
