import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import LinkButton from 'components/button/LinkButton'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { BsExclamationLg } from 'react-icons/bs'
import { motion } from 'framer-motion'
import { zoomIn } from 'utils/motion'

const ServiceSection = () => {
  return (
    <div
      className="relative bg-no-repeat bg-cover bg-scroll bg-center"
      style={{
        backgroundImage: `url(${ImageAssets.services})`,
        padding: '140px 0px'
      }}>
      <div
        className="absolute top-0 left-0 right-0 bottom-0"
        style={{
          backgroundColor: '#111A3ABF'
        }}></div>
      <motion.div
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 'some' }}
        variants={zoomIn(0, 0.5)}
        style={{
          zIndex: 2
        }}
        className="relative container mx-auto px-5 md:px-0">
        <div className="flex flex-col items-center w-8/12 mx-auto">
          <div
            className="flex bg-white items-center justify-center rounded-full"
            style={{
              width: '110px',
              height: '110px'
            }}>
            <BsExclamationLg size={40} color={COLORS.primary} />
          </div>
          <SizedBox height={30} />
          <h1
            style={{
              fontFamily: FONTS.extraBold,
              fontSize: '50px',
              lineHeight: '90%'
            }}
            className="text-center text-white">
            We Deliver Solution With The Goal Of A Trusting Relationships
          </h1>
          <p
            style={{
              margin: '30px 0px'
            }}
            className="text-center text-white">
            Appropriate for your specific business, making it easy for you to have quality IT
            services.
          </p>
          <LinkButton to="/contact">Get Started</LinkButton>
        </div>
      </motion.div>
    </div>
  )
}

export default ServiceSection
