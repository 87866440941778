import PageHeroSection from 'components/PageHeroSection'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import ImageAssets from 'assets/imageAssets'
import WhatWeProvide from './components/WhatWeProvide'
import ServiceClients from './components/ServiceClients'
import ServiceSection from './components/ServiceSection'

const ServicePage = () => {
  return (
    <div>
      <Header />
      <PageHeroSection title="Services" image={ImageAssets.contact} />
      <WhatWeProvide />
      <ServiceSection />
      <ServiceClients />
      <Footer />
    </div>
  )
}

export default ServicePage
