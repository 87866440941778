import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'

interface PageHeroSectionProps {
  image: string
  title: string
}
const PageHeroSection = ({ image, title }: PageHeroSectionProps) => {
  return (
    <div
      className="relative bg-no-repeat bg-cover bg-scroll bg-center"
      style={{
        padding: '170px 0px',
        backgroundImage: `url(${image})`
      }}>
      <div
        className="absolute top-0 left-0 right-0 bottom-0"
        style={{
          backgroundColor: '#000000B3'
        }}></div>
      <div
        style={{
          zIndex: 2
        }}
        className="container flex items-center justify-center mx-auto relative">
        <motion.h2
          variants={slideUpNoDelay()}
          whileInView="show"
          initial="hidden"
          viewport={{ once: true, amount: 'some' }}
          style={{
            fontFamily: FONTS.extraBold,
            fontSize: '50px'
          }}
          className="text-white">
          {title}
        </motion.h2>
      </div>
    </div>
  )
}

export default PageHeroSection
