import ImageAssets from 'assets/imageAssets'
import PageHeroSection from 'components/PageHeroSection'
import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'

const PrivacyPage = () => {
  return (
    <div>
      <Header />
      <PageHeroSection title="Privacy Policy" image={ImageAssets.contact} />
      <Footer />
    </div>
  )
}

export default PrivacyPage
