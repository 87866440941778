import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import AboutSection from './components/AboutSection'
import NewsLetter from './components/NewsLetter'
import OurService from './components/OurService'
import ProcessSection from './components/ProcessSection'
import TestomonialSection from './components/TestimonialSection'
import UnderHeroSection from './components/UnderHeroSection'
import HeroSection from './components/heroSection'

const HomePage = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <UnderHeroSection />
      <AboutSection />
      <OurService />
      <ProcessSection />
      <TestomonialSection />
      <NewsLetter />
      <Footer />
    </div>
  )
}

export default HomePage
