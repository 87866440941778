import ImageAssets from 'assets/imageAssets'
import UnderCard from './UnderCard'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'

const UnderHeroSection = () => {
  return (
    <StaggerWrapper>
      <div
        className="relative"
        style={{
          zIndex: 5,
          marginTop: '-40px'
        }}>
        <div className="relative container mx-auto px-5 md:px-0 flex flex-col md:flex-row gap-5">
          <UnderCard
            title="Trusted Services"
            description="Offering safe, secure and amazing end product for any and all products"
            image={ImageAssets.trusted}
          />
          <UnderCard
            mid
            title="24/7 Support"
            description="24 hour daily support, and routine maintanance for all web and mobile apps."
            image={ImageAssets.twentyFour}
          />
          <UnderCard
            title="Expert & Professional"
            description="Diverse team with over 10 years in the development and UI design industry."
            image={ImageAssets.support}
          />
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default UnderHeroSection
