import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import LinkButton from 'components/button/LinkButton'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'

const HeroSection = () => {
  return (
    <section
      className="relative bg-no-repeat bg-cover bg-scroll bg-center"
      id="hero"
      style={{
        paddingTop: '170px',
        paddingBottom: '40px',
        backgroundImage: `url(${ImageAssets.hero})`
      }}>
      <div
        className="absolute top-0 left-0 right-0 bottom-0"
        style={{
          backgroundColor: '#000000B3'
        }}></div>
      <StaggerWrapper>
        <div
          style={{
            zIndex: 2
          }}
          className="relative container mx-auto flex items-center justify-center">
          <div className="w-8/12 flex flex-col gap-2 items-center">
            <motion.h1
              variants={slideUpNoDelay()}
              style={{
                fontFamily: FONTS.extraBold,
                fontSize: '70px',
                lineHeight: '90%'
              }}
              className="text-white text-center">
              Strong & Certified
              <br />
              IT Solution Services
            </motion.h1>
            <SizedBox height={20} />
            <motion.p
              variants={slideUpNoDelay()}
              style={{
                fontFamily: FONTS.regular,
                fontSize: '32px'
              }}
              className="text-white text-center">
              Creating Effective Solutions For Business And Individuals
            </motion.p>
            <SizedBox height={20} />
            <LinkButton to="/contact">Get Started</LinkButton>
          </div>
        </div>
      </StaggerWrapper>
      <SizedBox height={100} />
    </section>
  )
}

export default HeroSection
