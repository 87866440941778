import logo from 'assets/images/logo.png'
import hero from 'assets/images/hero.jpg'
import plane from 'assets/images/plane.png'
import sitting from 'assets/images/sitting.png'
import trusted from 'assets/images/trusted.png'
import support from 'assets/images/support.png'
import case1 from 'assets/images/case1.jpg'
import case2 from 'assets/images/case2.jpg'
import case3 from 'assets/images/case3.jpg'
import twentyFour from 'assets/images/24.png'
import contact from 'assets/images/contact.jpg'
import services from 'assets/images/services.jpg'
import process1 from 'assets/images/process1.jpeg'
import process2 from 'assets/images/process2.jpeg'
import process3 from 'assets/images/process3.jpeg'
import process4 from 'assets/images/process4.jpeg'
import girl from 'assets/images/girl.svg'
import web from 'assets/images/web.svg'
import mock from 'assets/images/mock.png'
import whiteMan from 'assets/images/white-man.jpg'
import whiteWoman from 'assets/images/white-woman.jpg'
import mixWoman from 'assets/images/mix-woman.jpg'
import serviceBg from 'assets/images/service-bg.png'
import about from 'assets/images/about.jpeg'
import experience from 'assets/images/experience.jpeg'
import years from 'assets/images/years.png'

const ImageAssets = {
  experience,
  years,
  serviceBg,
  about,
  twentyFour,
  case1,
  case2,
  case3,
  support,
  trusted,
  girl,
  process1,
  process2,
  process3,
  process4,
  services,
  mixWoman,
  web,
  mock,
  contact,
  whiteMan,
  whiteWoman,
  sitting,
  plane,
  logo,
  hero
}

export default ImageAssets
