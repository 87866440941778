import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

const PercentageBar = ({ title, percentage, textColor = 'black' }: any) => {
  return (
    <div
      style={{
        marginBottom: '20px'
      }}
      className="w-full">
      <div
        style={{
          width: `${percentage}%`
        }}
        className="flex flex-row justify-between items-center">
        <p
          style={{
            color: textColor,
            fontFamily: FONTS.regular,
            fontSize: '20px'
          }}>
          {title}
        </p>
        <p
          style={{
            color: textColor,
            fontFamily: FONTS.regular,
            fontSize: '20px'
          }}>
          {percentage}%
        </p>
      </div>
      <div
        className="rounded-lg"
        style={{
          overflowY: 'hidden',
          marginTop: '10px',
          height: '5px',
          backgroundColor: '#D1D5DA',
          width: '100%'
        }}>
        <motion.div
          variants={slideInNoDelay('left', 'tween', 0.5)}
          className="h-full rounded-lg"
          style={{
            backgroundColor: COLORS.primary,
            width: `${percentage}%`
          }}
        />
      </div>
    </div>
  )
}

export default PercentageBar
