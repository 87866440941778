import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import FONTS from 'constants/fonts'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { slideUpNoDelay } from 'utils/motion'

interface LinkButtonProps {
  children: ReactNode
  to: string
  variant?: any
}
const LinkButton = ({ children, to, variant = slideUpNoDelay() }: LinkButtonProps) => {
  return (
    <motion.div variants={variant}>
      <Link
        className="flex items-center"
        style={{
          padding: '8px 16px',
          borderRadius: '100px',
          border: '1px solid white'
        }}
        to={to}>
        <div
          style={{
            width: 32,
            height: 32
          }}
          className="rounded-full flex items-center justify-center overflow-hidden">
          <img src={ImageAssets.logo} alt="" />
        </div>
        <SizedBox width={20} />
        <p
          style={{
            fontFamily: FONTS.bold
          }}
          className="flex-1 text-center text-white text-base">
          {children}
        </p>
        <SizedBox width={20} />
      </Link>
    </motion.div>
  )
}

export default LinkButton
