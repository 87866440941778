import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'

const NewsLetter = () => {
  return (
    <div
      style={{
        backgroundColor: COLORS.primary,
        padding: '90px 0px'
      }}>
      <div className="container px-5 md:px-0 mx-auto gap-5 flex flex-col md:flex-row items-center justify-between">
        <div>
          <h2
            style={{
              fontFamily: FONTS.extraBold,
              fontSize: '40px',
              lineHeight: '90%'
            }}
            className="text-white">
            Subscribe to our newsletter
          </h2>
          <p className="text-white">
            You can subscribe to our news letter to get updates on our offers
          </p>
        </div>
        <div
          style={{
            padding: '8px 16px',
            borderRadius: '5px'
          }}
          className="w-full md:w-5/12 bg-white flex flex-row gap-4 items-center">
          <input placeholder="Enter email" className="outline-none flex-1" type="text" />
          <button
            style={{
              backgroundColor: COLORS.primary,
              padding: '8px 12px',
              borderRadius: '5px'
            }}
            className="text-white">
            Subscribe
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewsLetter
