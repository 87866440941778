import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

const AboutCompany = () => {
  return (
    <StaggerWrapper>
      <div
        style={{
          margin: '90px 0px'
        }}>
        <div className="flex md:flex-row flex-col gap-5 container mx-auto px-5 md:px-0">
          <motion.img
            variants={slideInNoDelay('right')}
            style={{
              height: '400px'
            }}
            className="flex-1"
            src={ImageAssets.years}
            alt=""
          />
          <motion.div variants={slideInNoDelay('right')}>
            <div>
              <p
                style={{
                  color: COLORS.primary,
                  fontSize: '18px'
                }}
                className="font-bold">
                ABOUT COMPANY
              </p>
              <h3
                style={{
                  fontFamily: FONTS.extraBold,
                  fontSize: '40px',
                  lineHeight: '90%'
                }}>
                We Deal With All Aspects Of Professional{' '}
                <span
                  style={{
                    color: COLORS.primary
                  }}>
                  IT Services
                </span>
              </h3>
            </div>
            <SizedBox height={40} />
            <p
              style={{
                opacity: 0.6,
                fontFamily: FONTS.regular,
                lineHeight: '170%'
              }}>
              {`Chenius Solutions, a leading provider of innovative technology solutions! My name is
            ChatGPT and I am delighted to tell you a bit about our company. Chenius Solutions was
            founded with a mission to help businesses navigate the constantly evolving world of
            technology, providing tailored solutions that meet their unique needs. Our team of
            experienced professionals is dedicated to delivering high-quality services, including
            software development, cloud computing, cybersecurity, and more. At Chenius Solutions, we
            strive to understand our clients' goals and challenges, and work closely with them to
            deliver effective solutions that drive growth and success. Contact us today to learn
            more about how we can help your business thrive in the digital age.`}
            </p>
          </motion.div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default AboutCompany
