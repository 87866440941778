import SizedBox from 'components/SizedBox'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { AiFillStar } from 'react-icons/ai'
import { motion } from 'framer-motion'
import { fadeInNoDelay } from 'utils/motion'

const TestimonialCard = ({ children, image, name, title }: any) => {
  return (
    <motion.div
      variants={fadeInNoDelay('up')}
      style={{
        overflow: 'hidden',
        padding: '40px'
      }}
      className="relative flex-1">
      <div
        style={{
          transform: 'rotate(20deg)'
        }}
        className="bg-gray-50 absolute w-1/2 -top-20 -bottom-10 -left-20"></div>
      <div
        style={{
          zIndex: 2
        }}
        className="relative flex flex-row items-start gap-3">
        <img
          className="rounded-sm"
          style={{
            width: '90px'
          }}
          src={image}
          alt=""
        />
        <div>
          <h2
            style={{
              fontFamily: FONTS.bold,
              fontSize: '22px',
              lineHeight: '90%'
            }}>
            {name}
          </h2>
          <p
            style={{
              color: COLORS.primary
            }}>
            {title}
          </p>
        </div>
      </div>
      <SizedBox height={20} />
      <p
        className="text-sm"
        style={{
          opacity: 0.6
        }}>
        {children}
      </p>
      <SizedBox height={20} />
      <div
        style={{
          zIndex: 2
        }}
        className="relative flex flex-row items-center gap-3">
        <AiFillStar size={32} color={COLORS.primary} />
        <AiFillStar size={32} color={COLORS.primary} />
        <AiFillStar size={32} color={COLORS.primary} />
        <AiFillStar size={32} color={COLORS.primary} />
        <AiFillStar size={32} color={COLORS.primary} />
      </div>
    </motion.div>
  )
}

export default TestimonialCard
