import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import PercentageBar from './PercentageBar'
import { motion } from 'framer-motion'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import { slideInNoDelay } from 'utils/motion'

const AboutSection = () => {
  return (
    <StaggerWrapper>
      <div
        style={{
          margin: '90px 0'
        }}>
        <div className="container flex gap-10 md:flex-row flex-col mx-auto px-5 md:px-0">
          <motion.img
            variants={slideInNoDelay('right', 'tween')}
            className="flex-1"
            src={ImageAssets.about}
            alt=""
          />
          <motion.div variants={slideInNoDelay('right', 'tween')} className="flex-1">
            <div>
              <p
                style={{
                  color: COLORS.primary,
                  fontSize: '18px'
                }}
                className="font-bold">
                ABOUT CHENIUS
              </p>
              <h3
                style={{
                  fontFamily: FONTS.extraBold,
                  fontSize: '40px',
                  lineHeight: '90%'
                }}>
                We Are Here To Provide IT Solutions With Over 20 Years Of{' '}
                <span
                  style={{
                    color: COLORS.primary
                  }}>
                  Experience
                </span>
              </h3>
            </div>
            <h3
              style={{
                marginTop: '20px',
                fontSize: '20px'
              }}>
              Our IT services converge business and technology experts to help manage business
              categories.
            </h3>
            <p
              style={{
                marginTop: '20px',
                opacity: 0.6
              }}>
              Web and app designing is a powerful and sure fire way to set yourself apart from the
              crowd. With a unique webpage designed for specific needs, you can ensure that you are
              accesible globally through just a few clicks
            </p>
            <SizedBox height={20} />
            <StaggerWrapper delay={0.75}>
              <PercentageBar title="App Development" percentage={95} />
              <PercentageBar title="UI/UX Design" percentage={80} />
              <PercentageBar title="Web Development" percentage={93} />
            </StaggerWrapper>
          </motion.div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default AboutSection
