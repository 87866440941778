import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

const ProcessCard = ({ image, title, description, position }: any) => {
  return (
    <motion.div variants={slideInNoDelay('right')} className="flex flex-col items-center">
      <div className="relative">
        <img
          className="shadow-lg rounded-full"
          style={{
            border: '10px solid white',
            width: '200px',
            height: '200px'
          }}
          src={image}
          alt=""
        />
        <div
          style={{
            top: '70px',
            left: '-20px',
            backgroundColor: '#E9204FCC'
          }}
          className="absolute flex p-2 rounded-full items-center justify-center">
          <div
            className="flex items-center justify-center rounded-full"
            style={{
              height: '50px',
              width: '50px',
              backgroundColor: COLORS.primary
            }}>
            <p className="text-white text-lg font-medium">{position}</p>
          </div>
        </div>
      </div>
      <h2
        className="text-center"
        style={{
          margin: '20px 0px',
          fontFamily: FONTS.bold,
          fontSize: '20px',
          lineHeight: '90%'
        }}>
        {title}
      </h2>
      <p
        className="w-10/12 text-center text-base"
        style={{
          opacity: 0.6
        }}>
        {description}
      </p>
    </motion.div>
  )
}

export default ProcessCard
