import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { slideInNoDelay } from 'utils/motion'

const UnderCard = ({ image, title, description, mid = false }: any) => {
  return (
    <motion.div
      variants={slideInNoDelay('left')}
      style={{
        margin: !mid ? '20px 0px' : undefined,
        padding: '40px'
      }}
      className="shadow-lg bg-white relative rounded-lg flex items-center flex-col">
      <img
        style={{
          height: '100px'
        }}
        src={image}
        alt=""
      />
      <h2
        className="text-center"
        style={{
          fontFamily: FONTS.bold,
          fontSize: '25px',
          lineHeight: '90%',
          margin: '20px 0px'
        }}>
        {title}
      </h2>
      <p
        style={{
          opacity: 0.6
        }}
        className="text-center text-sm">
        {description}
      </p>
    </motion.div>
  )
}

export default UnderCard
