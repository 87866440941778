import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { fadeInNoDelay } from 'utils/motion'

const ProvisionCard = ({ image, title, description }: any) => {
  return (
    <motion.div
      variants={fadeInNoDelay('up')}
      style={{
        padding: '96px 40px'
      }}
      className="flex-1 flex flex-col justify-start items-center shadow-lg">
      <img
        style={{
          height: '120px'
        }}
        src={image}
        alt=""
      />
      <h2
        style={{
          margin: '25px 0',
          fontFamily: FONTS.bold,
          fontSize: '22px',
          lineHeight: '90%'
        }}>
        {title}
      </h2>
      <p
        style={{
          opacity: 0.6
        }}
        className="text-sm">
        {description}
      </p>
    </motion.div>
  )
}

export default ProvisionCard
