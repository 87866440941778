import ImageAssets from 'assets/imageAssets'
import Divider from 'components/Divider'
import SizedBox from 'components/SizedBox'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { Link } from 'react-router-dom'

const PostItem = ({ image, title, date }: any) => {
  return (
    <div className="flex flex-row items-stretch gap-3">
      <img
        style={{
          width: 100
        }}
        src={image}
        alt=""
      />
      <div>
        <h3
          style={{
            fontFamily: FONTS.bold
          }}
          className="text-white">
          {title}
        </h3>
        <p className="text-white text-sm">{date}</p>
      </div>
    </div>
  )
}

const HelpLink = ({ to, children }: any) => {
  return (
    <Link to={to} className="flex flex-row gap-3 items-center">
      <div
        style={{
          width: '10px',
          height: '2px'
        }}
        className="bg-white"></div>
      <p className="text-white capitalize">{children}</p>
    </Link>
  )
}

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: COLORS.gray
      }}>
      <div className="container mx-auto px-5 md:px-0">
        <SizedBox height={90} />
        <div className="flex flex-col md:flex-row gap-5">
          <div
            style={{
              width: '250px'
            }}>
            <Link to="/">
              <img
                style={{
                  width: 60
                }}
                src={ImageAssets.logo}
                alt=""
              />
            </Link>
            <SizedBox height={16} />
            <p className="text-white text-sm">
              Creating Efficient Solutions For Businesses And Individuals
            </p>
          </div>
          <div>
            <h3
              style={{
                fontFamily: FONTS.bold,
                fontSize: '22px'
              }}
              className="text-white">
              Help Links
            </h3>
            <SizedBox height={16} />
            <div>
              <HelpLink to="/contact">Book an appointment</HelpLink>
              <SizedBox height={16} />
              <HelpLink to="/about">About our company</HelpLink>
              <SizedBox height={16} />
              <HelpLink to="/careers">Work with us</HelpLink>
              <SizedBox height={16} />
            </div>
          </div>
          <div>
            <h3
              style={{
                fontFamily: FONTS.bold,
                fontSize: '22px'
              }}
              className="text-white">
              Contact Information
            </h3>
            <SizedBox height={16} />
            <div className="flex flex-col gap-3">
              <p className="text-white font">
                <span
                  style={{
                    fontFamily: FONTS.bold
                  }}>
                  Phone:
                </span>{' '}
                <a href="tel:+1 (650) 683-9219">+1 (650) 683-9219</a>
              </p>
              <p className="text-white">
                <span
                  style={{
                    fontFamily: FONTS.bold
                  }}>
                  Email:
                </span>{' '}
                <a href="mailto:info@cheniussolutions.com">info@cheniussolutions.com</a>
              </p>
              <p className="text-white">
                <span
                  style={{
                    fontFamily: FONTS.bold
                  }}>
                  Website:
                </span>{' '}
                <a href="https://www.cheniussolutions.com">https://www.cheniussolutions.com</a>
              </p>
            </div>
          </div>
          <div
            style={{
              maxWidth: '350px'
            }}>
            <h3
              className="text-white"
              style={{
                fontFamily: FONTS.bold,
                fontSize: '22px'
              }}>
              Affiliated Posts
            </h3>
            <SizedBox height={16} />
            <div>
              <PostItem
                image={ImageAssets.sitting}
                title="Smart office chair recognises what position you are sitting in"
                date="19 January 2023"
              />
              <SizedBox height={20} />
              <PostItem
                image={ImageAssets.plane}
                title="Will we ever see commercial plane flights without human pilots?"
                date="17 January 2023"
              />
            </div>
          </div>
        </div>
        <Divider top={40} bottom={40} />
        <div className="flex flex-row flex-wrap items-center justify-center md:justify-between">
          <p className="text-white text-center md:text-start">
            Copyright @ chenius solutions all rights reserved
          </p>
          <div className="flex mt-5 md:mt-0 flex-row items-center gap-5">
            <Link to="/terms">
              <p className="text-white">Terms And Conditions</p>
            </Link>
            <Link to="/privacy">
              <p className="text-white">Privacy Policy</p>
            </Link>
          </div>
        </div>
      </div>
      <SizedBox height={40} />
    </footer>
  )
}

export default Footer
