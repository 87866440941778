import ImageAssets from 'assets/imageAssets'
import PageHeroSection from 'components/PageHeroSection'
import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'
import ServiceClients from 'pages/services/components/ServiceClients'
import ExperienceSection from './components/ExperienceSection'
import ServiceSection from 'pages/services/components/ServiceSection'
import AboutCompany from './components/AboutCompany'

const AboutPage = () => {
  return (
    <div>
      <Header />
      <PageHeroSection title="About Us" image={ImageAssets.contact} />
      <AboutCompany />
      <ServiceSection />
      <ExperienceSection />
      <ServiceClients />
      <Footer />
    </div>
  )
}

export default AboutPage
