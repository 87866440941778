interface SizedBoxProps {
  width?: number | string
  height?: number | string
}
const SizedBox = ({ width, height }: SizedBoxProps) => {
  return (
    <div
      style={{
        height,
        width
      }}></div>
  )
}

export default SizedBox
