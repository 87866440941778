import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import PercentageBar from 'pages/home/components/PercentageBar'
import { motion } from 'framer-motion'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import { slideUpNoDelay } from 'utils/motion'

const ExperienceSection = () => {
  return (
    <StaggerWrapper>
      <div
        style={{
          marginTop: '-60px'
        }}>
        <div
          style={{
            backgroundImage: `url(${ImageAssets.experience})`
          }}
          className="container py-24 relative mx-auto px-5 md:px-24">
          <div
            className="absolute top-0 left-0 right-0 bottom-0"
            style={{
              backgroundColor: '#000000CC'
            }}></div>
          <div
            style={{
              zIndex: 2
            }}
            className="w-full relative md:w-6/12 mx-auto flex flex-col items-center">
            <motion.p
              variants={slideUpNoDelay()}
              style={{
                color: COLORS.primary,
                fontSize: '18px'
              }}
              className="font-bold">
              OUR EXPERIENCE
            </motion.p>
            <motion.h3
              variants={slideUpNoDelay()}
              className="text-center"
              style={{
                fontFamily: FONTS.extraBold,
                fontSize: '40px',
                lineHeight: '90%',
                color: 'white'
              }}>
              25 Years Of Experience With IT Solution{' '}
              <span
                style={{
                  color: COLORS.primary
                }}>
                Services
              </span>
            </motion.h3>
          </div>
          <SizedBox height={40} />
          <div
            style={{
              zIndex: 2
            }}
            className="relative">
            <PercentageBar textColor="white" title="App Development" percentage={95} />
            <PercentageBar textColor="white" title="UI/UX Design" percentage={80} />
            <PercentageBar textColor="white" title="Web Development" percentage={93} />
          </div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default ExperienceSection
