import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import { motion } from 'framer-motion'
import { fadeInNoDelay } from 'utils/motion'

const ServiceCard = ({ image, title }: any) => {
  return (
    <motion.div variants={fadeInNoDelay('up')} className="relative flex-1">
      <img src={image} alt="" />
      <div
        className="flex items-center justify-center absolute left-0 right-0 bottom-0"
        style={{
          padding: '20px',
          fontFamily: FONTS.bold,
          backgroundColor: '#000000CC'
        }}>
        <p className="text-white text-center">{title}</p>
      </div>
    </motion.div>
  )
}

const OurService = () => {
  return (
    <StaggerWrapper>
      <div
        className="relative bg-no-repeat bg-contain bg-scroll"
        style={{
          margin: '60px 0px',
          backgroundPosition: 'bottom center',
          padding: '60px 0px',
          backgroundImage: `url(${ImageAssets.serviceBg})`,
          backgroundColor: '#F4F7FC'
        }}>
        <div className="container mx-auto px-5 md:px-0">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <motion.div variants={fadeInNoDelay('left')} className="mb-10 md:mb-0 md:w-5/12">
              <p
                style={{
                  color: COLORS.primary,
                  fontSize: '18px'
                }}
                className="font-bold">
                OUR SERVICES
              </p>
              <h3
                style={{
                  fontFamily: FONTS.extraBold,
                  fontSize: '40px',
                  lineHeight: '90%'
                }}>
                We Offer IT Services
              </h3>
            </motion.div>
            <motion.p
              variants={fadeInNoDelay('left')}
              className="flex-1"
              style={{
                opacity: 0.6
              }}>
              Chenius Solutions team offers a variety of technical solution options. Looking for a
              simple marketing or portfolio website designed in no time, or looking to create a more
              feature reach management system. We also offer blockchain options, smart contracts,
              auditing and much more. Below are just some of the common client choices.
            </motion.p>
          </div>
          <SizedBox height={40} />
          <div className="flex flex-col md:flex-row items-center gap-5">
            <ServiceCard title="Web Development" image={ImageAssets.case1} />
            <ServiceCard title="App Development" image={ImageAssets.case2} />
            <ServiceCard title="QA Testing" image={ImageAssets.case3} />
          </div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default OurService
