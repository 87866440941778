import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import TestimonialCard from './TestimonialCard'
import ImageAssets from 'assets/imageAssets'
import SizedBox from 'components/SizedBox'
import { motion } from 'framer-motion'
import StaggerWrapper from 'components/wrappers/StaggerWrapper'
import { slideUpNoDelay } from 'utils/motion'

const TestomonialSection = () => {
  return (
    <StaggerWrapper>
      <div
        style={{
          margin: '100px 0px'
        }}>
        <div className="container mx-auto">
          <div className="flex flex-col items-center">
            <motion.p
              variants={slideUpNoDelay()}
              style={{
                color: COLORS.primary,
                fontSize: '18px'
              }}
              className="font-bold">
              ClIENT TESTIMONIALS
            </motion.p>
            <motion.h3
              variants={slideUpNoDelay()}
              className="text-center"
              style={{
                fontFamily: FONTS.extraBold,
                fontSize: '40px',
                lineHeight: '90%'
              }}>
              What Our Client Say?
            </motion.h3>
          </div>
          <SizedBox height={20} />
          <div className="flex gap-5 flex-col md:flex-row items-center">
            <TestimonialCard image={ImageAssets.mixWoman} name="Grace Nilam" title="Nutritionist">
              I have never worked with a more diverse team before, the cordination from people
              around the world amazed me, add that to the fact that my dream website was completed
              in under a month. I am extremely grateful that I took the chance on Chenius Solutions.
            </TestimonialCard>
            <TestimonialCard image={ImageAssets.whiteMan} name="Jonathan Ike" title="CFO">
              My team and I are grateful to Chenius Solutions for their amazing work on our custom
              CRM, automations, workflows, pipelines have never easier. They came up with an easy to
              use amazing design that my entire team loves.
            </TestimonialCard>
          </div>
        </div>
      </div>
    </StaggerWrapper>
  )
}

export default TestomonialSection
